/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
:root {
  --primary-color: #362bd5;
  --secondary-color: #e57715;
}
.primary-button{
  background-color: var(--primary-color) !important;
}
.button-border{
  border: 2px solid var(--primary-color) !important;
}
mat-checkbox {
  .mdc-checkbox .mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background {
    border-color: var(--primary-color) !important;
    background-color: var(--primary-color)  !important;
  }
}
.common-card-container .mat-mdc-form-field-subscript-wrapper {
  height: 0px;
}

::-webkit-scrollbar {
  width: 0px !important;
  overflow-y: scroll;
  background: #919191;
  box-shadow: inset 0 0 4px #7e7f80;
}

::-webkit-scrollbar-thumb {
  background: rgb(201, 201, 201);;
  border-radius: 10px;
}

.mdc-list-item.mdc-list-item--with-one-line{
  height: auto !important;
}

.mat-drawer-inner-container{
  overflow-x: hidden !important;
}

.mat-drawer-side {
  box-shadow: 4px 0px 10px 0px rgba(176, 170, 176, 0.75) !important;
  border-right-width: 0px !important;
  border-right-style: solid;
}

::ng-deep .navigate-icon.material-icons{
  font-size: 18px !important;

}

.mdc-list-item:hover::before {
  background-color: #00000000 !important;
  opacity:0 !important;
}
.filter mat-form-field  .mat-mdc-form-field-subscript-wrapper {
  height: 0px;
}

.mat-mdc-form-field-focus-overlay {
  background-color: transparent !important;
}

 .mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: transparent !important;
}

.filter .mdc-text-field--outlined {
  --mdc-outlined-text-field-container-shape: 5px !important;
}

.filter .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
  padding: 12px 0px !important;
  min-height: 0px !important;
}

.filter .cdk-overlay-pane:not(.mat-mdc-select-panel-above) div.mat-mdc-select-panel {
  border-radius: 15.5px !important;
}

.filter .mat-mdc-select-placeholder {
  font-size: 15px !important;
}

// .cdk-overlay-pane{
//   position: absolute !important;
//   bottom: -40px;
//   left: 45px;
//   border-radius: 7px !important;
//   box-shadow: 4px 0px 10px 0px rgba(176, 170, 176, 0.75) !important;
// }

.mat-pseudo-checkbox-minimal{
  display: none !important;
}


.mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
  padding-bottom: 0px !important;
}

.region-data .mdc-text-field--filled {
  --mdc-filled-text-field-active-indicator-height: 0px !important;
  --mdc-filled-text-field-focus-active-indicator-height: 0px !important;
}

.region-data .mdc-text-field{
  padding: 0 3px 0 0px !important;
}

.region-data .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-infix {
  min-height: 20px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.container .mdc-text-field--outlined {
  --mdc-outlined-text-field-container-shape: 0px !important;
}
.region-data .mat-mdc-form-field-subscript-wrapper{
  height: 0px;
}

.region-data .mdc-text-field--filled{
  height: 26px;
}


.mat-options .mdc-list-item__primary-text{
  margin-right: 0px !important;
}

// Mat toggle action icon display update.
.mdc-switch__icons {
  display: none !important;
}
// Mat slide toggle color before select.
.mat-mdc-slide-toggle {
  --mdc-switch-disabled-selected-handle-color: var(--primary-color) !important;
  --mdc-switch-disabled-unselected-handle-color: #544d4d !important;
  --mdc-switch-disabled-selected-track-color: rgba(55, 75, 165, 0.459) !important;
  --mdc-switch-disabled-unselected-track-color: #6d5e5e !important;
  --mdc-switch-unselected-focus-state-layer-color: #fafafa !important;
  --mdc-switch-unselected-pressed-state-layer-color: #fafafa !important;
  --mdc-switch-unselected-focus-track-color: rgba(0, 0, 0, 0.38) !important;
  --mdc-switch-unselected-hover-track-color: rgba(0, 0, 0, 0.38) !important;
  --mdc-switch-unselected-pressed-track-color: rgba(0, 0, 0, 0.38) !important;
  --mdc-switch-unselected-track-color: rgba(0, 0, 0, 0.38) !important;
  --mdc-switch-unselected-focus-handle-color: #fafafa !important;
  --mdc-switch-unselected-hover-handle-color: #fafafa !important;
  --mdc-switch-unselected-pressed-handle-color: #fafafa !important;
  --mdc-switch-unselected-handle-color: #fafafa !important;
  --mdc-switch-selected-icon-color: rgba(0, 0, 0, 0.38) !important;
  --mdc-switch-disabled-selected-icon-color: rgba(0, 0, 0, 0.38) !important;
  --mdc-switch-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38) !important;
  --mdc-switch-unselected-icon-color: rgba(0, 0, 0, 0.38) !important;
}
// Mat slide toggle color after select.
.mat-mdc-slide-toggle.mat-accent {
  --mdc-switch-selected-focus-state-layer-color: var(--primary-color) !important;
  --mdc-switch-selected-handle-color: var(--primary-color) !important;
  --mdc-switch-selected-hover-state-layer-color: var(--primary-color) !important;
  --mdc-switch-selected-pressed-state-layer-color: var(--primary-color) !important;
  --mdc-switch-selected-focus-handle-color: var(--primary-color) !important;
  --mdc-switch-selected-hover-handle-color: var(--primary-color) !important;
  --mdc-switch-selected-pressed-handle-color: var(--primary-color) !important;
  --mdc-switch-selected-focus-track-color: #3f32f752 !important;
  --mdc-switch-selected-hover-track-color: #3f32f752!important;
  --mdc-switch-selected-pressed-track-color:#3f32f752 !important;
  --mdc-switch-selected-track-color:#3f32f752 !important;
}
.mdc-switch {
  width: var(--mdc-switch-track-width);
  --mdc-switch-handle-height: 18px !important;
  --mdc-switch-handle-width: 18px !important;
  --mdc-switch-track-height: 12px !important;
  --mdc-switch-track-width: 33px !important;
}
//dropdown styles
// .mat-mdc-select-arrow svg {
//   margin-left: 5px !important;
// }

//mat form field styles
.mat-mdc-text-field-wrapper {
  height: 40px !important;
  flex: auto;
  align-items: center !important;
  place-content: center !important;
}
//search placeholder text align
.mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
  top: 17px !important;
}
//zen mail card default colors
.zen-mail-default-card-colors{
  background-color: var(--primary-color) !important;
  color:white !important;
  font-weight: 500 !important ;
}
//mat label align
.mat-mdc-text-field-wrapper.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above{
  --mat-mdc-form-field-label-transform: translateY( -24.75px) scale(var(--mat-mdc-form-field-floating-label-scale, 0.75)) !important;
}
// .cdk-overlay-container, .cdk-global-overlay-wrapper{
//   left: -15px !important;
// }
  .mat-mdc-dialog-container .mdc-dialog__surface{
    border-radius: var(--mdc-dialog-container-shape, 4px);
    overflow: visible !important;
  }
.common-header{
  margin: 15px 15px 0 35px;
}

.common-card-style, .loader-card-style{
  margin: 15px 15px 15px 15px;
}

.add-edit-container {
  padding: 25px 40px;
}

.add-edit-container.common-card-style {
  margin: 15px 15px 15px 35px;
}

.custom-select-panel .mat-mdc-option {
  min-height: 40px !important;
}
.mat-mdc-menu-item{
  padding: 0 10px !important;
}

.my-custom-dialog{
  margin: 10px !important;
}

.my-custom-dialog .mat-mdc-dialog-surface{
  background-color: transparent !important;
  border-radius: 10px !important;
}

.my-custom-dialog .mat-mdc-dialog-surface{
  box-shadow: none !important;
}

.log-dialog-container .mat-mdc-dialog-surface{
  height: 90vh !important;
  overflow: auto !important;
}

.text-editor-font-family .mat-mdc-menu-content{
  max-height: 200px !important;
}